declare global {
  interface Window {
    hj?: (...args: any[]) => void
  }
}

// https://help.hotjar.com/hc/en-us/articles/4405109971095 Hotjar API
class HotjarService {
  sendHotjarEvent(testId: string, bucket: string): void {
    if (window?.hj) {
      window.hj('event', `ab-${testId}-group-${bucket}`)
    }
  }
}

export const hotjarService = new HotjarService()
