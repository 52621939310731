import { Experiment } from '@naturalcycles/shared'
import { MainMenuItemRenderer } from '@src/components/menu-item-renderer/MenuItemRenderer.component'
import LanguageContext from '@src/context/language.context'
import { apply296Test2, filterMenuItems } from '@src/helpers/filterMenuItems'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { useTestStatus } from '@src/hooks/useTestStatus'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import { DisplayType, MenuItem } from '@src/typings/navigation-menu.d'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styles from './DesktopMenu.module.scss'

export const DesktopMenu = (): React.ReactElement => {
  const [AB296] = useTestStatus(Experiment.HCP_VISIBILITY, false, false)
  const isAb296Test2 = AB296 === 'test2'

  const country = useAppSelector(selectGeolocationCountry)
  const { locale } = React.useContext(LanguageContext)

  const data = useStaticQuery(graphql`
    query {
      allDatoCmsMenu {
        edges {
          node {
            id
            _allNavigationItemsLocales {
              locale
              value {
                id
                label
                componentType {
                  __typename
                  ...MenuLinkFragment
                  ...MinorMenuFragment
                  ...SubmenuFragment
                }
                region
                display
                uid
              }
            }
          }
        }
      }
    }
  `)

  const menuData = data.allDatoCmsMenu.edges[0].node._allNavigationItemsLocales
  const filteredMenuItems: MenuItem[] = filterMenuItems(
    menuData,
    locale,
    country,
    DisplayType.Desktop,
  )
  const mainMenuItems = filteredMenuItems.filter(
    item => item.componentType.__typename !== 'DatoCmsMinorMenu',
  )

  if (isAb296Test2) {
    apply296Test2(mainMenuItems, locale)
  }

  return (
    <ul
      uid="site-desktop-menu"
      className={classNames(styles.DesktopMenu, {
        [styles.DesktopMenu___extraColumn]: isAb296Test2,
      })}
    >
      {mainMenuItems.map(item => (
        <li key={item.id} className={styles.BurgerMenu__item}>
          <MainMenuItemRenderer item={item} />
        </li>
      ))}
    </ul>
  )
}
