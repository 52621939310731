import { isClientSide } from '@naturalcycles/js-lib'
import React from 'react'

export const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = React.useState<WindowDimensions>({
    width: null,
    height: null,
  })

  React.useEffect(() => {
    if (!isClientSide()) {
      setWindowDimensions({ width: null, height: null })
    } else {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
    }

    const handleResize = (): void => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
    }

    if (!isClientSide()) return
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

interface WindowDimensions {
  width: number | null
  height: number | null
}
