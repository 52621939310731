import { Experiment } from '@naturalcycles/shared'
import { Breakpoints } from '@src/hooks/useBreakpoint'
import { useTestStatus } from '@src/hooks/useTestStatus'
import { useWindowDimensions } from '@src/hooks/useWindowDimensions'
import classNames from 'classnames'
import { DatoCmsFileField } from 'dato-cms-assets'
import { graphql } from 'gatsby'
import React from 'react'
import styles from './MenuDropdown.module.scss'

export interface MenuDropdownProps {
  label: string
  uid: string
  dropdownList: {
    label: string
    sublabel: string
    href: string
    image: DatoCmsFileField
  }[]
}

export const MenuDropdown: React.FC<MenuDropdownProps> = ({ uid, label, dropdownList }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)
  const [height, setHeight] = React.useState(56)
  const [shouldRender, setShouldRender] = React.useState(false)

  const { width } = useWindowDimensions()
  const isDesktop = width && width >= Breakpoints.bpLarge

  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const ulRef = React.useRef<HTMLUListElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const timeoutRefs = React.useRef<ReturnType<typeof setTimeout>[]>([])
  const menuId = `mobile-menu-dropdown-${label.replaceAll(/\s+/g, '-').toLowerCase()}`

  const OPEN_DURATION = 0.3 // seconds
  const CLOSE_DURATION = 0.2 // seconds
  const MOBILE_INITIAL_HEIGHT = 56
  const DESKTOP_INITIAL_HEIGHT = 0
  const PADDING_ON_CLOSE = 0
  const PADDING_ON_OPEN = 8

  const [AB296] = useTestStatus(Experiment.HCP_VISIBILITY, false, false)
  const isAB296Test2 = AB296 === 'test2'

  const toggleDropdown = (): void => {
    setIsOpen(prevOpen => {
      if (!prevOpen) {
        openDropdown()
      } else {
        closeDropdownLogic()
      }
      return !prevOpen
    })
  }

  const openDropdown = (): void => {
    setShouldRender(true)
    setIsClosing(false)

    // Disable background scrolling
    document.body.style.overflow = 'hidden'

    if (isDesktop && ulRef.current) {
      expandDesktopDropdown()
    } else if (dropdownRef.current) {
      expandMobileDropdown()
    }
  }

  const closeDropdownLogic = (): void => {
    setIsClosing(true)

    // Re-enable background scrolling
    document.body.style.overflow = ''

    if (isDesktop && ulRef.current) {
      collapseDesktopDropdown()
    } else {
      collapseMobileDropdown()
    }

    // Delay unmounting for smooth transition
    scheduleUnmounting()
  }

  const expandDesktopDropdown = (): void => {
    if (ulRef.current) {
      ulRef.current.style.height = `${ulRef.current.scrollHeight}px`
    }
  }

  const expandMobileDropdown = (): void => {
    if (dropdownRef.current) {
      setHeight(dropdownRef.current.scrollHeight)
    }
  }

  const collapseDesktopDropdown = (): void => {
    if (ulRef.current) {
      ulRef.current.style.height = `${DESKTOP_INITIAL_HEIGHT}px`
    }
  }

  const collapseMobileDropdown = (): void => {
    setHeight(MOBILE_INITIAL_HEIGHT)
  }

  const scheduleUnmounting = (): void => {
    const timeoutId = setTimeout(() => {
      setShouldRender(false)
      setIsClosing(false)
      timeoutRefs.current = timeoutRefs.current.filter(id => id !== timeoutId)
    }, CLOSE_DURATION * 1000)
    timeoutRefs.current.push(timeoutId)
  }

  const closeDropdown = React.useCallback(() => {
    setIsOpen(false)
    setIsClosing(true)

    document.body.style.overflow = ''

    if (isDesktop && ulRef.current) {
      ulRef.current.style.height = `${DESKTOP_INITIAL_HEIGHT}px`
    } else {
      setHeight(MOBILE_INITIAL_HEIGHT)
    }

    setTimeout(() => {
      setShouldRender(false)
      setIsClosing(false)
    }, CLOSE_DURATION * 1000)

    // Return focus to the toggle button for accessibility
    buttonRef.current?.focus()
  }, [isDesktop])

  React.useEffect(() => {
    if (!isOpen) return

    const handleClickOutside = (event: MouseEvent): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown()
      }
    }

    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        closeDropdown()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, closeDropdown])

  React.useEffect(() => {
    return () => {
      document.body.style.overflow = ''
      timeoutRefs.current.forEach(timeoutId => clearTimeout(timeoutId))
      timeoutRefs.current = []
    }
  }, [])

  React.useEffect(() => {
    if (isOpen) {
      if (isDesktop && ulRef.current) {
        ulRef.current.style.height = `${ulRef.current.scrollHeight}px`
      } else if (dropdownRef.current) {
        setHeight(dropdownRef.current.scrollHeight)
      }
    }
  }, [isOpen, isDesktop, dropdownList])

  const mobileAnimationStyles = {
    height: `${height}px`,
    transition: `height ${isClosing ? CLOSE_DURATION : OPEN_DURATION}s ease`,
    overflow: 'hidden',
  }

  const desktopAnimationStyles = {
    height: isClosing ? PADDING_ON_CLOSE : `${ulRef.current?.scrollHeight || 0}px`,
    paddingTop: isClosing ? PADDING_ON_CLOSE : PADDING_ON_OPEN,
    paddingBottom: isClosing ? PADDING_ON_CLOSE : PADDING_ON_OPEN,
    overflow: 'hidden',
    transition: `height ${isClosing ? CLOSE_DURATION : OPEN_DURATION}s ease, padding ${isClosing ? CLOSE_DURATION : OPEN_DURATION}s ease`,
  }

  return (
    <div
      className={classNames(styles.MenuDropdown, {
        [styles.MenuDropdown___open]: isOpen,
        [styles.MenuDropdown___small]: isAB296Test2,
      })}
      ref={dropdownRef}
      style={!isDesktop ? mobileAnimationStyles : undefined}
    >
      <button
        uid={uid}
        ref={buttonRef}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls={menuId}
        onClick={toggleDropdown}
        className={styles.MenuDropdown__button}
      >
        <div className={styles.MenuDropdown__buttonContainer}>
          {label}
          <img
            className={styles.MenuDropdown__chevron}
            data-open={isOpen}
            src="/icons/chevron-icon.svg"
            alt=""
            aria-hidden="true"
          />
        </div>
      </button>

      {shouldRender && (
        <>
          <ul
            ref={ulRef}
            id={menuId}
            role="listbox"
            aria-label={`${label} options`}
            className={styles.MenuDropdown__container}
            style={isDesktop ? desktopAnimationStyles : undefined}
          >
            {dropdownList.map(item => (
              <li className={styles.MenuDropdown__item} key={item.href} onClick={closeDropdown}>
                <a
                  href={item.href}
                  className={styles.MenuDropdown__itemContainer}
                  role="option"
                  tabIndex={0}
                  uid={item.label}
                >
                  <p className={styles.MenuDropdown__label}>{item.label}</p>
                  <p className={styles.MenuDropdown__sublabel}>{item.sublabel}</p>
                  <img
                    aria-hidden="true"
                    src={item.image.url}
                    alt={item.image.alt}
                    className={styles.MenuDropdown__icon}
                  />
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.MenuDropdown__overlay} />
        </>
      )}
    </div>
  )
}

export const menuDropdownFragment = graphql`
  fragment MenuDropdownFragment on DatoCmsMenuDropdown {
    __typename
    id
    label
    dropdownList {
      id
      href
      label
      sublabel
      image {
        url
        alt
      }
    }
  }
`
