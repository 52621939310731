import languageContext from '@src/context/language.context'
import React from 'react'

interface LocalizedFooterIconProps {
  icon: string
  alt: string
  className?: string
  loading?: 'lazy' | 'eager'
}

export const LocalizedFooterIcon = ({
  icon,
  alt,
  className,
  loading = 'lazy',
}: LocalizedFooterIconProps): React.ReactNode => {
  const { locale, fallbackLocale } = React.useContext(languageContext)

  const getIconLocaleExtension = (): string => {
    if (fallbackLocale) {
      return `-${fallbackLocale}`
    }
    return `-${locale}`
  }

  return (
    <img
      className={className}
      src={`/icons/${icon}${getIconLocaleExtension()}.svg`}
      alt={alt}
      loading={loading}
    />
  )
}
