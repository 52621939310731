import React from 'react'

export interface CampaignProps {
  code?: string
  ignoreConfirmation?: boolean
  locale?: string
}

export type Campaign = CampaignProps | undefined

export default React.createContext<Campaign>(undefined)
