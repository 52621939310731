import LanguageContext from '@src/context/language.context'
import { getShopMenu, NavMenu, NavMenuMobilePlacements } from '@src/helpers/menu'
import { usePageType } from '@src/hooks/usePageType'
import { store } from '@src/store'
import { useContext, useMemo } from 'react'

export const useNavMenuMobile = (placement: NavMenuMobilePlacements): { menu: NavMenu | null } => {
  const pageType = usePageType()
  const { locale } = useContext(LanguageContext)
  const { country } = store.getState().geolocation
  const {
    shopify: { shippingLocation },
  } = store.getState()

  const menu = useMemo(() => {
    if (placement === NavMenuMobilePlacements.MobileMenu) {
      return getShopMenu(shippingLocation, true)
    }
    return null
  }, [country, locale, pageType, shippingLocation])

  return { menu }
}
