import { Promisable } from '@naturalcycles/js-lib'
import { addUtmParamsToLink, isExternalUrl, ncNavigate } from '@src/helpers/nc-navigate'
import { GatsbyLinkProps } from 'gatsby'
import React from 'react'

export interface NcLinkProps<TState> extends GatsbyLinkProps<TState> {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => Promisable<void>
}

export const NcLink = <T = any,>(props: NcLinkProps<T>): React.ReactElement => {
  const { to, children, state, replace, ...rest } = props
  const isExternal = isExternalUrl(to)

  async function internalOnClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): Promise<void> {
    e.preventDefault()
    await props.onClick?.(e)
    ncNavigate(to, typeof to === 'string' ? { state, replace } : undefined)
  }

  return (
    <a
      href={addUtmParamsToLink(to)}
      rel={isExternal ? 'noreferrer' : undefined}
      target={isExternal ? '_blank' : undefined}
      {...rest}
      onClick={!isExternal ? internalOnClick : undefined}
      // We expect the href not to match the server sometimes as it can depend on the window.location state
      suppressHydrationWarning
    >
      {children}
    </a>
  )
}
