import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

interface ClientProps {
  children?: ReactNode
}

export const ClientOnly = ({ children }: ClientProps): ReactElement | null => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return <>{hasMounted && children}</>
}
