import { _assert, IsoDateTime, localTime } from '@naturalcycles/js-lib'
import { countrySharedUtil } from '@naturalcycles/shared'
import qaContext from '@src/context/qa.context'
import { useAppSelector } from '@src/hooks/useAppSelector'
import {
  selectShopifyShippingLocation,
  setExpectedLocationByCountry,
  setShippingLocationByCountry,
} from '@src/shop/store/shopify.slice'
import { GeolocationCountries, success } from '@src/store/geolocation/geolocation.slice'
import Cookies from 'js-cookie'
import React from 'react'
import { useDispatch } from 'react-redux'
import timekeeper from 'timekeeper'
import Button from '../button/Button.component'
import styles from './QaMenu.module.scss'

export const QaMenu = (): React.ReactElement | null => {
  const [selectedCountry, setSelectedCountry] = React.useState<string | undefined>(
    Cookies.get('firebase-country-override'),
  )
  const [searchTerm, setSearchTerm] = React.useState('')
  const { displayMenu } = React.useContext(qaContext)
  const dispatch = useDispatch()
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const allCountryData = countrySharedUtil.getAllCountryData()
  const countries = [
    { code: 'Not set', name: 'Not set' },
    ...Object.entries(allCountryData).map(([code, { name }]) => ({
      code,
      name,
    })),
  ]
  const [filteredCountries, setFilteredCountries] = React.useState(countries)

  const onSelectCountryClicked = (): void => {
    _assert(selectedCountry)
    Cookies.set('firebase-country-override', selectedCountry, { expires: 1 })
    dispatch(success(selectedCountry as GeolocationCountries))
    dispatch(setExpectedLocationByCountry(selectedCountry as GeolocationCountries))
    dispatch(setShippingLocationByCountry(selectedCountry as GeolocationCountries))
  }

  const onClearCountryButtonClicked = (): void => {
    Cookies.remove('firebase-country-override')
    setSelectedCountry(undefined)
    location.href = '/'
  }
  const onSearchCountries = (): void => {
    const filtered = Object.entries(allCountryData)
      .filter(([, { name }]) => name.toLowerCase().includes(searchTerm.toLowerCase()))
      .map(([code, { name }]) => ({
        code,
        name,
      }))

    setFilteredCountries(filtered)

    if (filtered.length > 0) {
      setSelectedCountry(filtered[0]!.code)
    } else {
      setFilteredCountries(countries)
    }
  }

  const setExperiment = (id: string, bucket: string): void => {
    const initData = localStorage.getItem('initData')
    if (initData) {
      const parsedInitData = JSON.parse(initData)
      parsedInitData.data.experiment.assignments = {
        ...parsedInitData.data.experiment.assignments,
        [id]: { bucket, data: null },
      }
      localStorage.setItem('initData', JSON.stringify(parsedInitData))
    } else {
      localStorage.setItem(
        'initData',
        JSON.stringify({ data: { experiment: { assignments: { [id]: bucket } } } }),
      )
    }
    window.location.reload()
  }

  const clearExperiments = (): void => {
    const initData = localStorage.getItem('initData')
    if (initData) {
      const parsedInitData = JSON.parse(initData)
      parsedInitData.data.experiment.assignments = {}
      localStorage.setItem('initData', JSON.stringify(parsedInitData))
    }
    window.location.reload()
  }

  const timeInputRef = React.useRef<HTMLInputElement>(null)
  const setLocalTime: (time: IsoDateTime) => void = time => {
    timekeeper.travel(localTime(time).toDate())
  }

  const unsetLocalTime = (): void => {
    timekeeper.reset()
    if (timeInputRef.current) {
      timeInputRef.current.value = ''
    }
  }

  const promptForString = (prompt: string, defaultValue: string): string | null => {
    return window.prompt(prompt, defaultValue)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSearchCountries()
    }
  }

  if (!displayMenu) return null

  return (
    <div className={styles.QaMenu}>
      <h1>QA menu</h1>
      <br />

      <div className={styles.QaMenu__searchContainer}>
        <input
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyPress}
          className={styles.QaMenu__input}
        />
        <div className={styles.QaMenu__searchButton}>
          <button className={styles.QaMenu__button} onClick={onSearchCountries}>
            <svg viewBox="-3 -3 30 30" className={styles.QaMenu__magnifyingGlass}>
              <circle cx={10} cy={10} r={5} className={styles.QaMenu__magnifyingGlassLens} />
              <path d="M14,14 L18,18" className={styles.QaMenu__magnifyingGlassHandle} />
            </svg>
          </button>
        </div>
      </div>

      <div className={styles.QaMenu__selectContainer}>
        <select
          className={styles.QaMenu__select}
          value={selectedCountry}
          onChange={e => setSelectedCountry(e.target.value)}
        >
          {filteredCountries.map(({ code, name }) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </select>
        <p>
          Applied Shopify region is: <strong>{shippingLocation}</strong>
          <br />
          Applied Reddot country code is: <strong>{selectedCountry}</strong>
          <br />
        </p>
        <Button theme="brandPurple" onClick={onSelectCountryClicked}>
          Override country
        </Button>
      </div>

      <br />
      <Button theme="ghost" onClick={onClearCountryButtonClicked}>
        Clear country override
      </Button>
      <br />
      <br />
      <h2>Experiments</h2>
      <br />
      <Button
        theme="brandPurple"
        onClick={() => {
          const id = promptForString('Experiment ID', '')
          if (id) {
            setExperiment(id, promptForString('Bucket', '') || '')
          } else {
            alert('No experiment ID provided')
          }
        }}
      >
        Set AB test bucket
      </Button>
      <br />
      <Button theme="ghost" onClick={clearExperiments}>
        Clear AB test buckets
      </Button>
      <br />
      <br />
      <h2>Time</h2>
      <br />
      <label htmlFor="datetime-local">Set local time:</label>
      <input
        id="datetime-local"
        type="datetime-local"
        ref={timeInputRef}
        onChange={e => setLocalTime(`${e.target.value}:00Z` as IsoDateTime)}
        className={styles.QaMenu__input}
      />
      <br />
      <Button theme="ghost" onClick={unsetLocalTime}>
        Reset time
      </Button>
    </div>
  )
}
