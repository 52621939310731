import { _objectAssign } from '@naturalcycles/js-lib'
import { UserLocale } from '@naturalcycles/shared'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: UserLocale = {} as UserLocale

export const userLocaleSlice = createSlice({
  name: 'userLocale',
  initialState,
  reducers: {
    replace(state: UserLocale, action: PayloadAction<UserLocale>) {
      _objectAssign(state, action.payload)
    },
  },
})
