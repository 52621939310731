import { Experiment } from '@naturalcycles/shared'
import { BannerDiscountCode } from '@src/components/banner-discount-code/BannerDiscountCode.component'
import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { HeaderStandard } from '@src/components/header-standard/HeaderStandard.component'
import { MobileMenu } from '@src/components/mobile-menu/MobileMenu.component'
import { MobileMenu as NewMobileMenu } from '@src/components/mobile-menu-v2/MobileMenu.component'
import qaContext from '@src/context/qa.context'
import { getLayout } from '@src/helpers/layout'
import { redDotUnlockSequence } from '@src/helpers/redDot'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import { PageTypes, usePageType } from '@src/hooks/usePageType'
import { useTestStatus } from '@src/hooks/useTestStatus'
import BannerShop from '@src/shop/components/banner-shop/BannerShop.component'
import HeaderShop from '@src/shop/components/header-shop/HeaderShop.component'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import classNames from 'classnames'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Ab296HcpBanner } from '../ab-296-hcp-banner/Ab296HcpBanner.component'
import styles from './Header.module.scss'

export enum HeaderLayouts {
  StandardHome = 'StandardHome',
  Standard = 'Standard',
  Shop = 'Shop',
  ShopHome = 'ShopHome',
  ShopHomeLocationIndicator = 'ShopHomeLocationIndicator',
  StandardHomeDiscountBanner = 'StandardHomeDiscountBanner',
  StandardDiscountBanner = 'StandardDiscountBanner',
  ShopLocationIndicator = 'ShopLocationIndicator',
}

export enum HeaderPositions {
  Absolute = 'Absolute',
  Sticky = 'Sticky',
}

export enum HeaderThemes {
  TransparentSupportPurple = 'TransparentSupportPurple',
  SupportPurple = 'SupportPurple',
  Blue = 'Blue',
  White = 'White',
  ShopPurple = 'ShopPurple',
}

const standard = [
  HeaderLayouts.StandardHome,
  HeaderLayouts.Standard,
  HeaderLayouts.StandardHomeDiscountBanner,
  HeaderLayouts.StandardDiscountBanner,
]
const shop = [
  HeaderLayouts.Shop,
  HeaderLayouts.ShopLocationIndicator,
  HeaderLayouts.ShopHome,
  HeaderLayouts.ShopHomeLocationIndicator,
]

export interface HeaderProps {
  // Specifically added for DynamicTemplate, which has banner that are managed through Dato
  canHaveDiscountBanner?: boolean
}

export const Header = (props: HeaderProps): React.ReactElement => {
  const { canHaveDiscountBanner = true } = props
  const mobileMenuStatus = useAppSelector(selectMobileMenuStatus)
  const discountCode = useAppSelector(state => state.discountCode.code)
  const pageType = usePageType()
  const position = HeaderPositions.Sticky
  const [code, setCode] = React.useState('')
  const { toggleRedDot } = React.useContext(qaContext)
  const sequenceState = React.useState(0)

  const [AB293] = useTestStatus(Experiment.INSIGHTS_BANNER, false, false)
  const showInsightsBanner = AB293 === 'show'

  const { width } = useBreakpoint()
  const isMobile = width < Breakpoints.bpLarge

  // Determine if a discount code is present and if they saved their region
  React.useEffect(() => {
    setCode(discountCode || sessionStorage.getItem('discountCode') || '')
  }, [discountCode])

  const layout = getLayout(pageType, code || '')
  // Flip this switch if you want the non-sticky header back for the homepage
  // if (pageType === PageTypes.StandardHome && mobileMenuStatus === MobileMenuStatuses.Closed) {
  //   position = HeaderPositions.Absolute
  // }

  const className = classNames(
    styles.Header,
    styles[`Header__layout${layout}`],
    styles[`Header__position${position}`],
    {
      [styles.Header__hideBanners]: mobileMenuStatus === MobileMenuStatuses.Open,
    },
  )

  const showDiscountBanner = standard.includes(layout) && canHaveDiscountBanner
  const hideDiscountBanner = showInsightsBanner && [HeaderLayouts.StandardHome].includes(layout)

  if ([PageTypes.Campaign, PageTypes.WebView].includes(pageType)) {
    return <></>
  }

  return (
    <>
      {!isMobile && <Ab296HcpBanner />}

      <header
        uid="site-header"
        onTouchStart={e => redDotUnlockSequence(e, sequenceState, toggleRedDot)}
        className={className}
      >
        <Helmet>
          <body data-header-layout={layout} />
        </Helmet>

        <ClientOnly>
          {[HeaderLayouts.ShopHome, HeaderLayouts.ShopHomeLocationIndicator].includes(layout) &&
            !isMobile && <BannerShop />}

          {standard.includes(layout) && <HeaderStandard layout={layout} />}

          {shop.includes(layout) && <HeaderShop />}

          {showDiscountBanner && !hideDiscountBanner && <BannerDiscountCode />}

          {[HeaderLayouts.ShopHome, HeaderLayouts.ShopHomeLocationIndicator].includes(layout) &&
            isMobile && <BannerShop />}
        </ClientOnly>

        {isMobile && (shop.includes(layout) ? <MobileMenu /> : <NewMobileMenu />)}
      </header>
    </>
  )
}
