import { isClientSide } from '@naturalcycles/js-lib'
import { PersistedSellingPlan } from '@src/shop/store/shopify.model'
import classNames from 'classnames'
import React from 'react'
import styles from './ProductFrequencySelect.module.scss'

export interface ProductFrequencySelectProps {
  sellingPlans: PersistedSellingPlan[]
  initialSellingPlanId?: string
  onSellingPlanChange?: (sellingPlan: PersistedSellingPlan | null) => void
}

export const ProductFrequencySelect = ({
  sellingPlans,
  initialSellingPlanId,
  onSellingPlanChange,
}: ProductFrequencySelectProps): React.ReactElement => {
  const [selectedSellingPlan, setSelectedSellingPlan] = React.useState<PersistedSellingPlan>(
    sellingPlans.find(sp => sp.selling_plan_id === initialSellingPlanId) || sellingPlans[0]!,
  )

  const ref = React.useRef<HTMLUListElement>(null)

  const isClient = isClientSide()

  // Close dropdown when clicking outside
  React.useEffect(() => {
    if (!isClient) return
    const handleClick = (e: MouseEvent): void => {
      if (!ref.current?.contains(e.target as Node)) {
        setIsExpanded(false)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [isClient])

  const [isExpanded, setIsExpanded] = React.useState(false)

  React.useEffect(() => {
    onSellingPlanChange?.(selectedSellingPlan)
  }, [selectedSellingPlan])

  const icon = (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.ProductFrequencySelect__option__icon}
    >
      <path
        d="M4.74427 6.28759L8.28894 9.73901L11.7795 6.23412"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )

  return (
    <ul
      className={styles.ProductFrequencySelect}
      role="listbox"
      aria-expanded={isExpanded}
      ref={ref}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {sellingPlans.map((sp, i) => (
        <li
          key={sp.selling_plan_id}
          value={sp.selling_plan_id}
          className={styles.ProductFrequencySelect__option}
          aria-selected={selectedSellingPlan?.selling_plan_id === sp.selling_plan_id}
          role="option"
          onClick={() => setSelectedSellingPlan(sp)}
          aria-hidden={!isExpanded}
        >
          <span className={styles.ProductFrequencySelect__option__deliveryFrequency}>
            {i === 0 ? 'Delivery frequency' : ''}
          </span>
          <label className={styles.ProductFrequencySelect__option__label}>
            Every {sp.delivery_interval_count} {sp.delivery_interval}
            {sp.delivery_interval_count > 1 ? 's' : ''}
          </label>
          <span className={styles.ProductFrequencySelect__option__savings}>
            Save {sp.pricing_policy_fixed_adjustment_value}%
          </span>
          {i === 0 && icon}
        </li>
      ))}
      <li
        className={classNames(styles.ProductFrequencySelect__selectedOption)}
        role="option"
        aria-selected={true}
        aria-hidden={isExpanded}
      >
        <span className={styles.ProductFrequencySelect__option__deliveryFrequency}>
          Delivery frequency
        </span>
        <span className={styles.ProductFrequencySelect__option__label}>
          Every {selectedSellingPlan?.delivery_interval_count}{' '}
          {selectedSellingPlan.delivery_interval}
          {selectedSellingPlan.delivery_interval_count > 1 ? 's' : ''}
        </span>
        <span className={styles.ProductFrequencySelect__option__savings}>
          Save {selectedSellingPlan.pricing_policy_fixed_adjustment_value}%
        </span>
        {icon}
      </li>
    </ul>
  )
}
