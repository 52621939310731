import { brandPurple } from '@src/cnst/colors.cnst'
import LanguageContext from '@src/context/language.context'
import { useLocalePrefix } from '@src/hooks/useLocalePrefix'
import React from 'react'
import { NcLink } from '../nc-link/NcLink.component'

interface LogoProps {
  uid: string
  color?: string
  link?: boolean
  href?: string
}

export const Logo = ({
  color = brandPurple,
  link = true,
  href,
  uid,
}: LogoProps): React.ReactElement => {
  const { locale } = React.useContext(LanguageContext)
  const localizedHomeUrl = locale === 'en-US' ? useLocalePrefix('/') : useLocalePrefix('')

  const svg = (
    <svg
      uid={!link ? uid : ''}
      role="link"
      width="150"
      height="23"
      viewBox="0 0 150 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Natural Cycles Logo</title>
      <path
        d="M100.42 6.87109C100.515 6.53047 100.694 6.38449 101.09 6.38449H103.495C103.825 6.38449 104.061 6.72511 103.947 7.0852L98.9962 21.7903C98.9114 22.0336 98.7416 22.1699 98.487 22.1699H96.2801C95.8746 22.1699 95.6483 21.8195 95.7803 21.4594L97.4118 17.4206H96.6574C96.3839 17.4206 96.2047 17.2747 96.1198 17.0022L92.819 7.18252C92.6587 6.70565 92.9133 6.37476 93.3283 6.37476H95.6671C95.9972 6.37476 96.2141 6.45261 96.3273 6.83216L98.4021 14.4134L100.42 6.87109Z"
        fill={color}
      />
      <path
        d="M91.7725 7.04622C91.6876 7.07541 89.7543 7.74692 89.7543 7.74692C89.5185 7.82478 89.2921 7.71773 89.179 7.51336C88.3962 6.02435 87.1419 5.06088 85.3217 5.06088C82.7942 5.06088 80.908 7.21166 80.908 9.80039C80.908 12.3891 82.832 14.5107 85.3217 14.5107C87.2173 14.5107 88.5094 13.518 89.2356 12.1264C89.4242 11.7565 89.6317 11.6689 90.0183 11.8247L91.9611 12.5935C92.0837 12.6422 92.1874 12.7395 92.2346 12.8757C92.2818 13.0022 92.2818 13.1482 92.2252 13.265C90.9426 16.0484 88.3491 17.7709 85.3217 17.7709C81.1344 17.7709 77.7393 14.1993 77.7393 9.80039C77.7393 5.4015 81.1438 1.82983 85.3217 1.82983C88.2076 1.82983 90.7822 3.49401 92.0931 6.1898C92.2818 6.59854 92.1309 6.9197 91.7725 7.04622Z"
        fill={color}
      />
      <path
        d="M54.7466 6.13147C53.8224 6.13147 52.7284 6.77379 52.0588 7.40637L51.757 7.69833V6.93923C51.757 6.49156 51.493 6.3845 51.144 6.3845H49.1541C48.8335 6.3845 48.626 6.61807 48.626 6.93923V17.0022C48.626 17.3525 48.8335 17.5666 49.1447 17.5666H51.2289C51.5401 17.5666 51.7476 17.3039 51.7476 17.0216V12.6519C51.7476 10.6471 52.7284 9.53769 54.6618 9.53769C55.0484 9.53769 55.2371 9.32359 55.2371 9.02189V6.66673C55.2465 6.30665 55.039 6.13147 54.7466 6.13147Z"
        fill={color}
      />
      <path
        d="M19.3051 6.18005C16.4193 6.18005 14.0615 8.77852 14.0615 11.9804C14.0615 15.1725 16.4098 17.7807 19.3051 17.7807C20.3897 17.7807 21.3988 17.4109 22.2193 16.7004L22.5116 16.4474V17.0897C22.5116 17.3525 22.7191 17.5666 22.9737 17.5666H25.0863C25.3881 17.5666 25.6333 17.3135 25.6333 17.0021V6.97808C25.6333 6.62773 25.4635 6.38443 25.0768 6.38443H23.1152C22.6908 6.38443 22.5116 6.57907 22.5116 6.96835V7.52308C21.8326 6.84184 20.7386 6.18005 19.3051 6.18005ZM19.9653 14.8708C18.3903 14.8708 17.2963 13.6835 17.2963 11.9804C17.2963 10.3065 18.4186 9.08994 19.9653 9.08994C21.5402 9.08994 22.738 10.3356 22.738 11.9804C22.7285 13.5959 21.512 14.8708 19.9653 14.8708Z"
        fill={color}
      />
      <path
        d="M60.8862 6.18005C58.0003 6.18005 55.6426 8.77852 55.6426 11.9804C55.6426 15.1725 57.9909 17.7807 60.8862 17.7807C61.9707 17.7807 62.9798 17.4109 63.8003 16.7004L64.0927 16.4474V17.0605C64.0927 17.3427 64.3473 17.5763 64.5925 17.5763H66.7428C67.0068 17.5763 67.2143 17.3525 67.2143 17.0897V6.87103C67.2143 6.60826 67.0068 6.39416 66.7522 6.39416H64.5831C64.2907 6.39416 64.0927 6.5888 64.0927 6.90996V7.54254C63.4137 6.84183 62.3197 6.18005 60.8862 6.18005ZM61.5463 14.8708C59.9714 14.8708 58.8774 13.6835 58.8774 11.9804C58.8774 10.3065 59.9997 9.08994 61.5463 9.08994C63.1213 9.08994 64.319 10.3356 64.319 11.9804C64.3096 13.5959 63.093 14.8708 61.5463 14.8708Z"
        fill={color}
      />
      <path
        d="M43.8065 16.3696V17.1287C43.8065 17.372 44.0045 17.5764 44.2403 17.5764H46.2868C46.6357 17.5764 46.8527 17.4012 46.8527 17.0022V6.92952C46.8527 6.5889 46.6263 6.38452 46.2774 6.38452H44.4289C44.0045 6.38452 43.8065 6.58889 43.8065 7.06577V12.1459C43.8065 14.2578 42.7408 15.0363 41.477 15.0363C39.9775 15.0363 39.1853 14.0437 39.1853 12.1556L39.157 6.96845C39.157 6.62782 38.9873 6.39425 38.5817 6.39425H36.5258C36.1108 6.39425 35.9316 6.59863 35.9316 6.99764V11.9805C35.9316 15.5229 37.7895 17.7321 40.9395 17.7321C42.3541 17.7321 43.3443 17.1093 43.7782 16.3696C43.7876 16.3404 43.8065 16.3502 43.8065 16.3696Z"
        fill={color}
      />
      <path
        d="M3.1122 8.25319C3.1122 8.23373 3.13107 8.22399 3.14993 8.24346L9.1197 17.3137C9.23287 17.4792 9.3932 17.5765 9.61011 17.5765H12.3922C12.76 17.5765 12.9487 17.3429 12.9487 17.012V2.70592C12.9487 2.39449 12.7506 2.17065 12.4205 2.17065H10.3646C10.0534 2.17065 9.83645 2.40422 9.83645 2.78377V12.8078C9.83645 12.8273 9.80816 12.837 9.79873 12.8175L3.36684 2.43342C3.2631 2.26797 3.09334 2.17065 2.90472 2.17065H0.60358C0.169757 2.17065 0 2.37503 0 2.78377V16.9731C0 17.304 0.207481 17.5668 0.546994 17.5668H2.56521C2.91416 17.5668 3.13107 17.3332 3.13107 16.9731V8.25319H3.1122Z"
        fill={color}
      />
      <path
        d="M109.484 17.7515C106.476 17.7515 103.995 15.2017 103.948 12.068C103.92 10.5109 104.524 8.98295 105.58 7.8735C106.608 6.79324 107.956 6.19958 109.38 6.19958C111.682 6.19958 113.568 7.37716 114.567 9.42089C114.615 9.50848 114.615 9.61553 114.577 9.71285C114.539 9.81018 114.464 9.8783 114.369 9.91723L112.181 10.6763C112.134 10.6958 112.087 10.6958 112.049 10.6958C111.908 10.6958 111.766 10.6179 111.691 10.4914C111.163 9.68366 110.446 9.27491 109.55 9.27491C108.107 9.27491 106.976 10.4525 106.976 11.961C106.976 13.4597 108.126 14.6665 109.55 14.6665C110.446 14.6665 111.191 14.248 111.691 13.45C111.776 13.3137 111.889 13.2456 112.04 13.2456C112.134 13.2456 112.238 13.2651 112.361 13.3137L114.162 13.9463C114.332 13.995 114.454 14.1117 114.52 14.2577C114.577 14.3842 114.567 14.5302 114.501 14.6567C113.473 16.6032 111.597 17.7515 109.484 17.7515Z"
        fill={color}
      />
      <path
        d="M73.2785 17.5666C73.6181 17.5666 73.8067 17.333 73.8067 17.0119V14.8611C73.8067 14.501 73.6369 14.3064 73.1937 14.2966H72.5807C72.2128 14.2966 72.0054 14.0825 72.0054 13.6835V2.79334C72.0054 2.47218 71.8262 2.22888 71.4584 2.22888H69.4307C69.0158 2.22888 68.8271 2.41379 68.8271 2.79334V14.0825C68.8271 16.5545 69.7042 17.5666 71.8356 17.5666H73.2785Z"
        fill={color}
      />
      <path
        d="M131.269 14.9292C131.543 15.153 131.533 15.4937 131.25 15.8246C130.175 17.0995 128.987 17.771 126.61 17.771C123.536 17.771 121.027 15.1725 121.027 11.9707C121.027 8.77854 123.527 6.13142 126.61 6.17035C130.006 6.21901 132.175 8.81747 132.175 12.1069C132.175 12.4962 131.977 12.6908 131.599 12.6908H124.168C124.253 13.9657 125.224 14.9292 126.61 14.9292C127.912 14.9292 128.582 14.3063 129.025 13.8197C129.27 13.5375 129.553 13.5375 129.808 13.7419L131.269 14.9292ZM126.714 8.71041C125.441 8.71041 124.536 9.46951 124.347 10.54H128.978C128.77 9.51817 127.931 8.71041 126.714 8.71041Z"
        fill={color}
      />
      <path
        d="M120.31 17.5666C120.649 17.5666 120.838 17.333 120.838 17.0119V14.8611C120.838 14.501 120.668 14.3064 120.225 14.2966H119.612C119.244 14.2966 119.037 14.0825 119.037 13.6835V2.79334C119.037 2.47218 118.857 2.22888 118.49 2.22888H116.462C116.047 2.22888 115.858 2.41379 115.858 2.79334V14.0825C115.858 16.5545 116.735 17.5666 118.867 17.5666H120.31Z"
        fill={color}
      />
      <path
        d="M35.1964 16.5642C34.838 16.8951 34.0835 17.6834 31.9616 17.6834C30.396 17.6834 28.4155 16.5448 28.4344 13.4403V9.28467C28.4344 9.23601 28.3967 9.19708 28.3495 9.19708H27.5007C27.1518 9.19708 26.916 8.9927 26.916 8.61315L26.9254 6.92951C26.9254 6.63755 27.0858 6.38451 27.5102 6.38451H28.3589C28.4061 6.38451 28.4438 6.34558 28.4438 6.29692V2.75446C28.4438 2.37491 28.6419 2.17053 29.0097 2.17053H31.0845C31.4146 2.17053 31.5749 2.33598 31.5749 2.6766V6.29692C31.5749 6.34558 31.6126 6.38451 31.6598 6.38451H34.1967C34.5645 6.38451 34.772 6.59862 34.772 6.9879L34.7625 8.58396C34.7625 8.97324 34.5551 9.18735 34.1873 9.18735H31.6598C31.6126 9.18735 31.5749 9.22627 31.5749 9.27493V13.2164C31.5749 14.1215 32.0747 14.6762 32.8858 14.6762C33.197 14.6762 33.4328 14.6081 33.6308 14.4524C33.8666 14.2675 34.1778 14.2869 34.3759 14.5302C34.6588 14.8903 35.3284 15.8051 35.3284 15.8051C35.4793 16.0582 35.4227 16.3501 35.1964 16.5642Z"
        fill={color}
      />
      <path
        d="M132.92 14.9292C132.637 15.153 132.684 15.4839 132.806 15.6688C133.203 16.282 134.542 17.7807 137.371 17.7807C140.568 17.7807 142.171 16.0484 142.171 14.2966C142.171 12.175 140.927 11.1629 137.673 10.3454C136.362 10.0145 136.041 9.75175 136.041 9.44033C136.041 9.10944 136.353 8.79801 137.126 8.79801C138.154 8.79801 138.899 9.08024 140.078 9.78095C140.342 9.93666 140.615 9.95613 140.842 9.6447L141.775 8.38927C142.002 8.05838 141.86 7.76641 141.596 7.57177C140.398 6.70562 139.078 6.18982 137.38 6.18982C134.693 6.18982 132.976 7.78588 132.976 9.75175C132.976 11.5814 134.136 12.7006 136.739 13.3234C138.267 13.6932 138.984 13.849 138.984 14.3258C138.984 14.7248 138.569 15.1044 137.447 15.1044C136.324 15.1044 135.372 14.4231 134.938 13.9463C134.73 13.7127 134.429 13.6932 134.165 13.9073C134.155 13.9073 133.014 14.8514 132.92 14.9292Z"
        fill={color}
      />
      <path
        d="M145.95 0.460083C143.79 0.460083 142.035 2.27052 142.035 4.4995C142.035 6.72848 143.79 8.53891 145.95 8.53891C148.11 8.53891 149.864 6.72848 149.864 4.4995C149.864 2.27052 148.11 0.460083 145.95 0.460083ZM145.95 7.00102C144.61 7.00102 143.516 5.88166 143.516 4.48976C143.516 3.1076 144.601 1.97851 145.95 1.97851C147.289 1.97851 148.383 3.09787 148.383 4.48976C148.383 5.88166 147.289 7.00102 145.95 7.00102Z"
        fill={color}
      />
    </svg>
  )

  if (link) {
    return (
      <NcLink uid={uid} to={href || localizedHomeUrl}>
        {svg}
      </NcLink>
    )
  }
  return svg
}
