import { createSelector, createSlice } from '@reduxjs/toolkit'
import { Thunk } from '@src/store'

export enum MobileMenuStatuses {
  Open = 'Open',
  Closed = 'Closed',
}

export interface MobileMenuState {
  status: MobileMenuStatuses
}

const initialState: MobileMenuState = {
  status: MobileMenuStatuses.Closed,
}

const slice = createSlice({
  name: 'mobileMenu',

  initialState,

  reducers: {
    open(mobileMenu: MobileMenuState) {
      mobileMenu.status = MobileMenuStatuses.Open
    },

    close(mobileMenu: MobileMenuState) {
      mobileMenu.status = MobileMenuStatuses.Closed
    },
  },
})

export const openMobileMenu = (): Thunk => async dispatch => {
  dispatch(slice.actions.open())
}

export const closeMobileMenu = (): Thunk => async dispatch => {
  dispatch(slice.actions.close())
}

export const selectMobileMenu = ({
  mobileMenu,
}: {
  mobileMenu: MobileMenuState
}): MobileMenuState => mobileMenu

export const selectMobileMenuStatus = createSelector(
  [selectMobileMenu],
  (mobileMenu): MobileMenuStatuses => {
    return mobileMenu.status
  },
)

export default slice
