import { _jsonParseIfPossible, isServerSide, StringMap } from '@naturalcycles/js-lib'
import { cookieService } from '@naturalcycles/shared'

export const getUtmQueryParams = (): StringMap => {
  if (isServerSide() && process.env['GATSBY_ENV'] !== 'test') return {}
  const qp = new URLSearchParams(globalThis.location.search)
  const utms = {
    // utms from a Cookie
    ..._jsonParseIfPossible(cookieService.getCookie('utms')),
    // utms from QueryString
    ...Object.fromEntries(
      [...qp.entries()].filter(([key, value]) => {
        return key.startsWith('utm') && value !== undefined && value !== ''
      }),
    ),
  }
  return utms
}

export const setCurrentUtmsInUrl = (target: string): URL => {
  const targetUrl = new URL(target)
  const currentUtmParams = new URLSearchParams()

  const qp = getUtmQueryParams()
  for (const [key, value] of Object.entries(qp)) {
    if (key.startsWith('utm_') && value && !currentUtmParams.has(key)) {
      currentUtmParams.append(key, value)
    }
  }

  targetUrl.search = currentUtmParams.toString()

  return targetUrl
}
