import { EU_COUNTRIES, OURA_SHIPPING_COUNTRIES } from '@naturalcycles/shared'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import { GeolocationCountries } from '@src/store/geolocation/geolocation.slice'
import { DisplayType, MenuItem, Region } from '@src/typings/navigation-menu.d'

export function filterMenuItems(
  menuData: { locale: string; value: MenuItem[] }[],
  locale: string,
  country: string,
  displayType: DisplayType,
): MenuItem[] {
  // The menu uses a static query component, which limits data flexibility.
  // To adapt it dynamically, we filter the data here based on the page's locale
  const localeData = menuData.find(item => item.locale === locale)

  return localeData!.value.filter(item => {
    let regionMatch = true

    if (item.region === Region.OuraSellingCountries) {
      regionMatch = OURA_SHIPPING_COUNTRIES.includes(country)
    } else if (item.region === Region.Usa) {
      regionMatch = country === GeolocationCountries.US
    } else if (item.region === Region.Europe) {
      regionMatch = EU_COUNTRIES.includes(country)
    }

    const displayMatch = !item.display || item.display === displayType

    return regionMatch && displayMatch
  })
}

/**
 * Mutates the menu items to apply the 296 test 2 changes.
 */
export function apply296Test2(menuItems: MenuItem[], locale: SupportedLocale): void {
  if (locale !== SupportedLocale.enUS) {
    return
  }

  const item = menuItems.find(i => i.label === 'Cycle Matters Library')
  if (item) {
    item.label = 'Cycle Matters'
  }
  menuItems.push({
    id: '296-hcp',
    componentType: {
      __typename: 'DatoCmsMenuLink',
      id: '296-hcp-link',
      href: '/hcp',
    },
    label: 'For Clinicians',
    uid: '296-hcp',
  })
}
