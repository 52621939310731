import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { NavMenu } from '@src/hooks/useNavMenuFooter'
import React from 'react'
import { NcLink } from '../nc-link/NcLink.component'
import styles from './FooterLinks.module.scss'

interface FooterLinksProps {
  children: NavMenu
  theme?: 'grey' | 'purple'
}

const FooterLinks = ({ children, theme = 'grey' }: FooterLinksProps): React.ReactElement => {
  return (
    <ClientOnly>
      <ul className={styles.FooterLinks}>
        {children.map(navMenuItem => {
          if (!navMenuItem.href && process.env['GATSBY_ENV'] !== 'prod') {
            console.error('Missing href in footer nav menu item', navMenuItem.uid)
          }
          return (
            <li key={navMenuItem.text}>
              <NcLink
                uid={navMenuItem.uid}
                to={navMenuItem.href || '/'}
                className={
                  theme === 'grey' ? styles.FooterLinks__link : styles.FooterLinks__linkPurpleTheme
                }
                aria-label={navMenuItem.text === 'FAQs' ? 'Frequently Asked Questions' : ''}
              >
                {navMenuItem.text}
              </NcLink>
            </li>
          )
        })}
      </ul>
    </ClientOnly>
  )
}

export default FooterLinks
