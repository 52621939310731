import { useLocation } from '@reach/router'

export enum PageTypes {
  Campaign = 'Campaign',
  StandardHome = 'StandardHome',
  Standard = 'Standard',
  Shop = 'Shop',
  ShopHome = 'ShopHome',
  Unknown = 'Unknown',
  WebView = 'WebView',
}

export const ConsumerPageTypes = [PageTypes.StandardHome, PageTypes.Standard]

export const ShopPageTypes = [PageTypes.Shop, PageTypes.ShopHome]

const patternMap = {
  [PageTypes.Campaign]: [
    /^\/c\/.+/,
    /^(\/|\/es\/|\/pt-br\/|\/sv\/|\/ca\/)refer-a-friend\/?$/,
    /^(\/|\/es\/|\/pt-br\/|\/sv\/|\/ca\/)join-me\/?$/,
    /^(\/|\/es\/|\/pt-br\/|\/sv\/|\/ca\/)join-me-plan\/?$/,
    /^\/samsung\/?$/,
  ],
  [PageTypes.StandardHome]: [/^\/$/, /^\/es\/?$/, /^\/pt-br\/?$/, /^\/sv\/?$/, /^\/ca\/?$/],

  // Adding this to standard pages otherwise it is interpretted as a press page
  [PageTypes.Standard]: [/^\/newsletter-signup/],
  [PageTypes.ShopHome]: [
    /^\/shop\/row?$/,
    /^\/shop\/se?$/,
    /^\/shop\/us?$/,
    /^\/shop\/uk?$/,
    /^\/shop\/eu?$/,
  ],
  [PageTypes.Shop]: [/^\/shop/],
  [PageTypes.WebView]: [/\/app-legal/],
}

export const usePageType = (): PageTypes => {
  const { pathname } = useLocation()

  let pageType =
    (Object.entries(patternMap).find(([_pageType, patterns]) =>
      patterns.some(pattern => pattern.exec(pathname)),
    )?.[0] as PageTypes) || PageTypes.Unknown

  if (pageType === PageTypes.Unknown) {
    pageType = PageTypes.Standard
  }

  return pageType
}
