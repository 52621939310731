import { SupportedLanguage, SupportedLocale, SupportedUrl } from './translations.cnst'

export interface LocaleObject {
  language: SupportedLanguage
  locale: SupportedLocale
  name: string
  url: SupportedUrl
}

const locales: LocaleObject[] = [
  {
    language: SupportedLanguage.en,
    locale: SupportedLocale.enUS,
    name: 'English',
    url: SupportedUrl.enUS,
  },
  {
    language: SupportedLanguage.es,
    locale: SupportedLocale.esUS,
    name: 'Español',
    url: SupportedUrl.esUS,
  },
  {
    language: SupportedLanguage.pt,
    locale: SupportedLocale.ptBR,
    name: 'Português',
    url: SupportedUrl.ptBR,
  },
  {
    language: SupportedLanguage.sv,
    locale: SupportedLocale.svSE,
    name: 'Svenska',
    url: SupportedUrl.svSE,
  },
  {
    language: SupportedLanguage.en,
    locale: SupportedLocale.enCA,
    name: 'English (CA)',
    url: SupportedUrl.enCA,
  },
]

export default locales
