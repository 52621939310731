export enum DisplayType {
  Mobile = 'Mobile',
  Desktop = 'Desktop',
}

export enum Region {
  OuraSellingCountries = 'OuraSellingCountries',
  Europe = 'Europe',
  Usa = 'Usa',
}
export interface BaseMenuEntry {
  id: string
  label: string
  uid: string
  /**
   * If provided, the menu entry will only be shown to users in these countries.
   */
  region?: Region
  /**
   * Specifies if entry will show on 'mobile' or 'desktop'.
   * If omitted, the menu entry will be shown on both.
   */
  display?: DisplayType
}

export interface LinkMenuEntry extends BaseMenuEntry {
  componentType: {
    __typename: 'DatoCmsMenuLink'
    id: string
    href: string
  }
}

export interface MinorMenuEntry extends BaseMenuEntry {
  componentType: {
    __typename: 'DatoCmsMinorMenu'
    id: string
    href: string
  }
}

export interface SubmenuEntry extends BaseMenuEntry {
  componentType: {
    __typename: 'DatoCmsSubmenu'
    id: string
    submenu: {
      __typename: 'DatoCmsMenuDropdown' | 'DatoCmsMenuLibrary'
      id: string
      label: string
      dropdownList?: MenuDropdownProps['dropdownList']
      button: {
        text: string
        url: string
      }
      categories: MenuCategory[]
      desktopHeading: string
      desktopContext: string
      desktopButton: {
        text: string
        url: string
      }
    }
  }
}

export type MenuItem = LinkMenuEntry | MinorMenuEntry | SubmenuEntry
