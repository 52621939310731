import { Experiment } from '@naturalcycles/shared'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import languageContext from '@src/context/language.context'
import { Breakpoints } from '@src/hooks/useBreakpoint'
import { useTestStatus } from '@src/hooks/useTestStatus'
import { useWindowDimensions } from '@src/hooks/useWindowDimensions'
import { mixpanelService } from '@src/srv/mixpanel.service'
import React from 'react'
import { NcLink } from '../nc-link/NcLink.component'
import styles from './Ab296HcpBanner.module.scss'

export interface Ab296HcpBannerProps {}

export function Ab296HcpBanner({}: Ab296HcpBannerProps): React.ReactNode {
  const { locale } = React.useContext(languageContext)
  const [ab296] = useTestStatus(Experiment.HCP_VISIBILITY, false, false)
  const isAb296Test1 = ab296 === 'test' && locale === SupportedLocale.enUS
  const { width } = useWindowDimensions()
  const isMobile = width && width < Breakpoints.bpLarge

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    const target = event.currentTarget
    const link = target.getAttribute('href') || ''
    mixpanelService.track('HCP_BANNER_CLICK', { link })
  }

  return isAb296Test1 ? (
    <div className={styles.HcpBanner}>
      <div className={styles.HcpBanner__content}>
        <NcLink to="/hcp" className={styles.HcpBanner__link} onClick={handleClick}>
          For Healthcare Professionals
        </NcLink>
        {!isMobile && <p className={styles.HcpBanner__pipe}>|</p>}
        <NcLink to="/publications" className={styles.HcpBanner__link} onClick={handleClick}>
          Research
        </NcLink>
      </div>
    </div>
  ) : null
}
