import LanguageContext from '@src/context/language.context'
import { getShopMenu, NavMenu } from '@src/helpers/menu'
import { usePageType } from '@src/hooks/usePageType'
import { store } from '@src/store'
import { useContext, useEffect, useState } from 'react'

export const useNavMenuDesktop = (): { menu: NavMenu | null } => {
  const pageType = usePageType()
  const { locale } = useContext(LanguageContext)
  const { country } = store.getState().geolocation
  const {
    shopify: { shippingLocation },
  } = store.getState()
  const [menu, setMenu] = useState<NavMenu | null>(null)

  useEffect(() => {
    const menuValue = getShopMenu(shippingLocation)

    setMenu(menuValue)
  }, [pageType, country, shippingLocation, locale])

  return { menu }
}
