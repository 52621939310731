class RetargetingService {
  init(): void {
    if (typeof window !== 'undefined') {
      const storedNumber = window.localStorage.getItem('visitCount')
      const visitCount = storedNumber ? parseInt(storedNumber) + 1 : 1

      const path = window.location.pathname

      const visitedPages = window.localStorage.getItem('visitedPages')
      const added = `${visitedPages ? visitedPages + ',' : ''}${path}`
      const uniqueArray = [...new Set(added.split(','))]
      const arrayToString = uniqueArray.join(',')

      window.localStorage.setItem('visitedPages', arrayToString)
      window.localStorage.setItem('visitCount', visitCount.toString())

      // switch back on if needed again
      // Send data to mixpanel to keep track of trends
      // mixpanelService.track('WebSessionHistory', { visits: visitCount, pages: uniqueArray })
    }
  }

  getVisitCount(): number | undefined {
    if (typeof window !== 'undefined') {
      const storedNumber = window.localStorage.getItem('visitCount')
      const toNumber = storedNumber ? parseInt(storedNumber) : 0
      return toNumber
    }
  }

  getVisitedPages(): string[] | undefined {
    if (typeof window !== 'undefined') {
      const visitedPages = window.localStorage.getItem('visitedPages')
      const toArray = visitedPages ? visitedPages.split(',') : []
      return toArray
    }
  }
}

export const retargetingService = new RetargetingService()
