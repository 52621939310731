import { SupportedLocale } from '@src/cnst/translations.cnst'
import { HeaderLayouts } from '@src/components/header/Header.component'
import languageContext from '@src/context/language.context'
import { PageTypes, ShopPageTypes } from '@src/hooks/usePageType'
import React from 'react'

export const getLayout = (pageType: PageTypes, code: string): HeaderLayouts => {
  const { locale } = React.useContext(languageContext)
  const showBannerInHomepageLangs = [SupportedLocale.svSE, SupportedLocale.ptBR]

  if (pageType === PageTypes.ShopHome) {
    return HeaderLayouts.ShopHome
  }
  if (ShopPageTypes.includes(pageType)) {
    return HeaderLayouts.Shop
  }
  if (pageType === PageTypes.StandardHome && code && showBannerInHomepageLangs.includes(locale)) {
    return HeaderLayouts.StandardHomeDiscountBanner
  }
  if (pageType === PageTypes.StandardHome) {
    return HeaderLayouts.StandardHome
  }
  if (pageType === PageTypes.Standard && code) {
    return HeaderLayouts.StandardDiscountBanner
  }
  return HeaderLayouts.Standard
}
