import { _stringEnumKeyOrUndefined } from '@naturalcycles/js-lib'
import { RegionSlug, ShippingLocation } from '@src/shop/cnst/shopify.cnst'
import { MouseEvent } from 'react'

export enum NavMenuMobilePlacements {
  MobileMenu = 'MobileMenu',
  MobileMenuExtra = 'MobileMenuExtra',
}

export interface NavMenuItem {
  id?: string
  text: string
  href: string
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}
export type NavMenu = NavMenuItem[]

export interface NavMenuLocaleUrls {
  [locale: string]: string
}

export const getShopMenu = (shippingLocation?: ShippingLocation, isMobile?: boolean): NavMenu => {
  const regionKeyOrBlocked = _stringEnumKeyOrUndefined(ShippingLocation, shippingLocation) || 'ROW'
  const regionSlug = regionKeyOrBlocked === 'Blocked' ? 'row' : RegionSlug[regionKeyOrBlocked]
  const commonPart = [
    {
      id: 'menu-all-products',
      text: 'All Products',
      href: `/shop/${regionSlug}`,
    },
    {
      id: 'menu-faq',
      text: 'FAQ',
      href: '/shop/page/faq',
    },
  ]

  const extra = [
    {
      id: 'menu-back',
      text: 'Go back to naturalcycles.com',
      href: '/',
    },
  ]

  return [...commonPart, ...(isMobile ? extra : [])]
}
