import { Experiment } from '@naturalcycles/shared'
import { useExperimentBucket } from '@src/hooks/useExperimentBucket'
import { hotjarService } from '@src/srv/hotjar.service'
import { mixpanelService } from '@src/srv/mixpanel.service'
import React from 'react'

/**
 * Returns the experiment bucket or null.
 * Optionally logs events to Mixpanel and Hotjar.
 *
 * @param experiment - The experiment to check the user's bucket against.
 * @param logHotjarEvent - Whether to enable Hotjar tracking.
 * @param logImpression - Whether to log the mixpanel impression or not.
 * @returns An array with a single element: `null` if loading, `bucket` test bucket otherwise.
 */
export const useTestStatus = (
  experiment: Experiment,
  logHotjarEvent = false,
  logImpression = false,
): [string | undefined | null] => {
  const { bucket } = useExperimentBucket(experiment)

  React.useEffect(() => {
    if (bucket) {
      if (logImpression) {
        mixpanelService.testImpression(experiment, bucket)
        console.log(`Impression logged for experiment ${experiment} in group ${bucket}`)
      }
      if (logHotjarEvent) {
        hotjarService.sendHotjarEvent(experiment, bucket)
      }
    }
  }, [bucket])

  return [bucket]
}
