import React from 'react'
import { ClientOnly } from '../client-only/ClientOnly.component'
import styles from './FooterHeading.module.scss'

interface FooterHeadingProps {
  children: any
  theme?: 'grey' | 'purple'
}

const FooterHeading = ({ children, theme = 'grey' }: FooterHeadingProps): React.ReactElement => {
  return (
    <ClientOnly>
      <h2 className={theme === 'grey' ? styles.FooterHeading : styles.FooterHeadingPurpleTheme}>
        {children}
      </h2>
    </ClientOnly>
  )
}

export default FooterHeading
