export const brandSalmon = '#fa837f'
export const alertRed = '#d83030'
export const deep = '#1a206d'
export const textGrey = '#757575'

export const brandGrey = '#f5f3f3'
export const supportGreyOne = '#f1eaea'
export const supportGreyTwo = '#e2d4d4'

export const brandBlue = '#488EE8'
export const brandPurple = '#72035D'
export const supportPurple = '#560246'
export const alertGreen = '#87B738'
export const softBlack = '#1a1a1a'
export const accessibleBlue = '#2674d9'
export const supportPink = '#FFD7D6'
export const brandPink = '#FFE8E8'

// app color palette
export const bleeding = '#D294EF'
export const emotions = '#FFDF5C'
export const pain = '#FF9A75'
export const skin = '#FF928E'
export const sexDrive = '#FD90B4'
export const excluded = '#FF736F'
export const lhTests = '#8DBEFF'
