import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { mixpanelService } from '@src/srv/mixpanel.service'
import {
  closeMobileMenu,
  MobileMenuStatuses,
  openMobileMenu,
  selectMobileMenuStatus,
} from '@src/store/mobileMenu/mobileMenu.slice'
import classNames from 'classnames'
import React from 'react'
import styles from './BurgerIcon.module.scss'

interface BurgerIconProps {
  color?: string
}

const BurgerIcon = ({ color = 'white' }: BurgerIconProps): React.ReactNode => {
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectMobileMenuStatus)

  const [keyUser, setKeyUser] = React.useState(false)

  const onKeyDown = React.useCallback((event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      setKeyUser(true)
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
  }, [])

  const className = classNames(styles.BurgerIcon, {
    [styles.BurgerIcon__open]: status === MobileMenuStatuses.Open,
    [styles.BurgerIcon__closed]: status === MobileMenuStatuses.Closed,
    [styles.BurgerIcon__focus]: !keyUser,
  })

  const verb = status === MobileMenuStatuses.Open ? 'Close' : 'Open'
  const label = `${verb} navigation menu`
  const expanded = status === MobileMenuStatuses.Open
  const style = { backgroundColor: color }

  const onClick = React.useCallback(() => {
    if (status === MobileMenuStatuses.Closed) {
      mixpanelService.trackClick('BurgerMenuOpen')
      dispatch(openMobileMenu())
    } else {
      mixpanelService.trackClick('BurgerMenuClose')
      dispatch(closeMobileMenu())
    }
  }, [status])

  return (
    <button
      className={className}
      aria-label={label}
      aria-expanded={expanded}
      onClick={onClick}
      uid="burger-button"
    >
      <span className={styles.BurgerIcon__stripe} style={style} />
      <span className={styles.BurgerIcon__stripe} style={style} />
      <span className={styles.BurgerIcon__stripe} style={style} />
    </button>
  )
}

export default BurgerIcon
