import { pDelay } from '@naturalcycles/js-lib'
import { isExternalUrl } from '@src/helpers/nc-navigate'
import { mixpanelService } from '@src/srv/mixpanel.service'
import classNames from 'classnames'
import React from 'react'
import styles from './Button.module.scss'

export enum ButtonElements {
  Anchor = 'Anchor',
  Button = 'Button',
}

export enum ButtonFocusAfterClick {
  Keep = 'Keep',
  Blur = 'Blur',
}

export enum ButtonFontSizes {
  MediumText = 'Button__mediumText',
  RegularText = 'Button__regularText',
  LargeText = 'Button__largeText',
}

export type ButtonSizes = 'flexible' | 'large' | 'regular' | 'small'

export type ButtonThemes =
  | 'accessibleBlue'
  | 'ghost'
  | 'inactiveGhost'
  | 'ghostWhite'
  | 'brandPurple'
  | 'inactiveBrandPurple'
  | 'brandBlue'
  | 'brandSalmon'
  | 'transparentPurple'
  | 'white'
  | 'inactiveAccessibleBlue'
  | 'whiteBrandPurple'
  | 'inactiveWhiteBrandPurple'
  | 'accessibleBlueOutline'
  | 'appearAsLink'
  | 'ghostWithIcon'

export interface ButtonProps {
  id?: string
  uid?: string
  children?: any
  size?: ButtonSizes
  theme?: ButtonThemes
  underline?: boolean
  href?: string
  elementName?: string
  onClick?: (event: React.MouseEvent) => boolean | void | Promise<void>
  focusAfterClick?: ButtonFocusAfterClick
  fontSize?: ButtonFontSizes
  ariaLabel?: string
  shop?: boolean
  disabled?: boolean
  suppressHydrationWarning?: boolean
}

const defaultOnClick = async (): Promise<void> => undefined

const Button = ({
  id,
  uid,
  children = <></>,
  elementName = '',
  size = 'regular',
  theme = 'brandPurple',
  underline = false,
  href = '',
  onClick = defaultOnClick,
  focusAfterClick = ButtonFocusAfterClick.Keep,
  fontSize = ButtonFontSizes.RegularText,
  ariaLabel,
  shop = false,
  disabled = false,
  suppressHydrationWarning = false,
}: ButtonProps): React.ReactNode => {
  const buttonElement = href ? ButtonElements.Anchor : ButtonElements.Button
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const afterClick = async (): Promise<void> => {
    if (focusAfterClick === ButtonFocusAfterClick.Blur) {
      await pDelay(100)
      if (buttonRef.current) {
        buttonRef.current.blur()
      }
    }
  }

  const handleClick = async (event: any): Promise<void> => {
    if (disabled) {
      return
    }
    if (buttonElement === ButtonElements.Button && focusAfterClick === ButtonFocusAfterClick.Blur) {
      // have to prevent the default behavior when clicking buttons in order to
      // be able to blur them afterwards. not sure why but only works this way
      event.preventDefault()
    }

    // run any custom onClick stuff first
    if ((await onClick(event)) === false) {
      await afterClick()
      return
    }

    // only do tracking if an elementName has been set
    if (elementName !== '') {
      if (href !== '') {
        // if we're tracking a link then we have to prevent the default
        // navigation event and give mixpanel 300ms to track it first
        event.preventDefault()
        mixpanelService.trackLink(elementName, href)
      } else {
        // if it's just a regular click then it's fine: just track it with
        // no other side effects
        mixpanelService.trackClick(elementName)
      }
    }

    await afterClick()
  }

  const props = {
    className: classNames(styles.Button, styles[theme], styles[fontSize], {
      [styles.appearAsLink___underlined]: underline,
      [styles.Button__regular]: size === 'regular',
      [styles.Button__noMinWidth]: size === 'flexible',
      [styles.Button__small]: size === 'small',
      [styles.Button__shop__small]: shop && size === 'small',
      [styles.Button__medium]: fontSize === ButtonFontSizes.MediumText,
      [styles.Button__shop]: shop,
      [styles.Button__disabled]: disabled,
    }),
    onClick: handleClick,
    'aria-label': ariaLabel,
    uid,
    id,
    elementname: elementName, // lowercase for no errors
  }

  const isInternalLink = !isExternalUrl(href)

  return (
    <>
      {buttonElement === ButtonElements.Anchor && (
        <a
          target={isInternalLink ? '_self' : '_blank'}
          rel={!isInternalLink ? 'external noopener noreferrer' : ''}
          href={href}
          suppressHydrationWarning={suppressHydrationWarning}
          {...props}
        >
          {children}
        </a>
      )}

      {buttonElement === ButtonElements.Button && (
        <button {...props} ref={buttonRef} suppressHydrationWarning={suppressHydrationWarning}>
          {children}
        </button>
      )}
    </>
  )
}

export default Button
