import { StringMap } from '@naturalcycles/js-lib'
import { OptimoveWebSDK } from 'optimove-websdk'

export const optimoveWebSdk = new OptimoveWebSDK({
  tenantToken: process.env['OPTIMOVE_TENANT_TOKEN']!,
  configVersion: process.env['OPTIMOVE_CONFIG_VERSION']!,
  debug: false,
  // enabled only in production
  enabled: process.env['GATSBY_ENV'] === 'production',
})

const categoryMap: StringMap = {
  '/cyclematters': 'CycleMatters',
  '/how-does-natural-cycles-work': 'How Does It Work?',
  '/is-natural-cycles-right-for-me': 'Is It Right For Me?',
  '/the-science': 'The Science',
  '/reviews': 'Testimonials',
  '/faqs': 'FAQs',
}

class OptimoveService {
  async trackImpression(): Promise<void> {
    const url = location.href
    const pageTitle = document.title

    const match = Object.keys(categoryMap).find(k => location.href.includes(k))
    const pageCategory = categoryMap[match!] || 'Website'

    await Promise.all([
      optimoveWebSdk.setPageVisit(url, pageTitle, pageCategory),
      optimoveWebSdk.reportEvent(`pageViewWeb`, {
        pageName: url,
      }),
    ])
  }
}

export const optimoveService = new OptimoveService()
