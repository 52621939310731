export interface NewsletterAccountInput {
  email: string
  source?: string
  campaign?: string
  consentTo?: string
}

class GtmService {
  init(): void {
    window.dataLayer ||= []
  }

  trackImpression(): void {
    window.dataLayer.push({
      event: 'pageViewed',
      pageViewed: document.location.pathname,
      'View Type': 'Website',
    })
  }

  trackLeadCapture({ email, source, campaign, consentTo }: NewsletterAccountInput): void {
    window.dataLayer.push({
      event: 'LeadCapture',
      email,
      source,
      campaign,
      consentTo,
    })
  }
}

export const gtmService = new GtmService()
