import { getFetcher, StringMap, UnixTimestampMillis } from '@naturalcycles/js-lib'
import { BackendResponseFM, BackendResponseFMResp } from '@naturalcycles/shared'
import { getUtmQueryParams } from '@src/helpers/queryParams'
import { apiService } from '@src/srv/api.service'
import { onBackendResponse, store } from '@src/store'

// Not re-using apiFetcher to avoid CORS preflight
const fetcher = getFetcher({
  baseUrl: process.env['GATSBY_NCBACKEND3_URL'],
  credentials: 'omit', // to avoid CORS preflight
})

interface InitStorage {
  data: BackendResponseFM
  timestamp: UnixTimestampMillis
}

export class WebInitService {
  private storageKey = 'initData'
  private validityInMs = 24 * 60 * 60 * 1000 // 24h

  async init(): Promise<void> {
    const storage = this.getStorageData()

    if (storage) {
      store.dispatch(onBackendResponse(storage.data))
      return
    }

    const utms = getUtmQueryParams()
    const { backendResponse } = await webInitService.getWebInitData(utms)

    if (!backendResponse) {
      return
    }

    store.dispatch(onBackendResponse(backendResponse))
    const data: InitStorage = {
      timestamp: Date.now() as UnixTimestampMillis,
      data: backendResponse,
    }
    window.localStorage.setItem(this.storageKey, JSON.stringify(data))
  }

  getStorageData(): InitStorage | null {
    const storage = window.localStorage.getItem(this.storageKey)

    if (!storage) return null

    const parsed = JSON.parse(storage)
    const now = Date.now()
    const savedTimestamp = parsed.timestamp

    if (now - savedTimestamp > this.validityInMs) {
      return null
    }

    return parsed
  }

  async getWebInitData(searchParams: StringMap): Promise<BackendResponseFMResp> {
    return await fetcher.get('api/v3/app/webInit', {
      // Kirill: Avoiding CORS preflight by sending everything over searchParams and not http headers
      searchParams: {
        ...searchParams,
        ...apiService.getHeaders(),
      },
    })
  }
}

export const webInitService = new WebInitService()
