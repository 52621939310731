import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { ShopifyPrice } from '@src/shop/store/shopify.model'
import React from 'react'
import { ProductOptionSource } from '../product-options/ProductOptions.component'
import styles from './ProductFrequencyControlOption.module.scss'

export interface ProductFrequencyControlOptionProps
  extends React.PropsWithChildren<{
    tags: string[]
    price: ShopifyPrice['price']
    source: ProductOptionSource
    label: string
    isSelected: boolean
    preselected?: boolean
  }> {}

export const ProductFrequencyControlOption = ({
  tags,
  price,
  label,
  source,
  isSelected,
  preselected,
  children,
}: ProductFrequencyControlOptionProps): React.ReactElement => {
  const { pricingCountry } = useAppSelector(state => state.userLocale)
  if (!pricingCountry) {
    return <></>
  }

  const inputRef = React.useRef<HTMLInputElement>(null)
  const contentRef = React.useRef<HTMLDivElement>(null)

  return (
    <ClientOnly>
      <div
        className={styles.ProductFrequencyControlOption}
        aria-selected={isSelected}
        onClick={e => {
          if (!contentRef.current?.contains(e.target as Node)) {
            inputRef.current?.click()
          }
        }}
      >
        <div className={styles.ProductFrequencyControlOption__header}>
          <label className={styles.ProductFrequencyControlOption__label}>
            <div className={styles.ProductFrequencyControlOption__upperRow}>
              <input
                type="radio"
                name={`productFrequency-${source}`}
                className={styles.ProductFrequencyControlOption__input}
                value={label}
                defaultChecked={preselected}
                ref={inputRef}
              />
              <div className={styles.ProductFrequencyControlOption__labelText}>{label}</div>
              <div className={styles.ProductFrequencyControlOption__tags}>
                {tags.map(tag => (
                  <div key={tag} className={styles.ProductFrequencyControlOption__tag}>
                    {tag}
                  </div>
                ))}
              </div>
              <div className={styles.ProductFrequencyControlOption__price}>
                {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: price.currencyCode,
                }).format(parseFloat(price.amount))}
              </div>
            </div>
          </label>
          {children && (
            <div className={styles.ProductFrequencyControlOption__content} ref={contentRef}>
              {children}
            </div>
          )}
        </div>
      </div>
    </ClientOnly>
  )
}
