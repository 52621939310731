import { SupportedLanguage, SupportedLocale } from '@src/cnst/translations.cnst'
import React from 'react'

export default React.createContext<{
  language: SupportedLanguage
  locale: SupportedLocale
  /**
   * If we have occasions where we want to reuse some data for a locale
   * we can specify a fallback locale here. This is part of how we handle
   * Canada often using the same data as the US.
   */
  fallbackLocale?: SupportedLocale
}>({
  language: SupportedLanguage.en,
  locale: SupportedLocale.enUS,
  fallbackLocale: SupportedLocale.enUS,
})
