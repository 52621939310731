import LanguageContext from '@src/context/language.context'
import React from 'react'

const localePrefixes: { [locale: string]: string } = {
  'en-US': '',
  'es-US': '/es',
  'pt-BR': '/pt-br',
  'sv-SE': '/sv',
  'en-CA': '/ca',
}

export const useLocalePrefix = (pathname: string): string => {
  const { locale } = React.useContext(LanguageContext)
  return localePrefixes[locale] + pathname
}
