import React from 'react'

export enum Breakpoints {
  'bpExtraSmall' = 320,
  'bpSmall' = 375,
  'bpMedium' = 767,
  'bpLarge' = 1140,
}

function getBreakpoint(width: number): Breakpoints {
  // prettier-ignore
  return width > Breakpoints.bpLarge ? Breakpoints.bpLarge
    : width > Breakpoints.bpMedium ? Breakpoints.bpMedium
    : width > Breakpoints.bpSmall? Breakpoints.bpSmall
    : Breakpoints.bpExtraSmall
}

/**
 * Hook for breakpoint detection in React components
 *
 * Returns the exact width of the window in pixels and the breakpoint that this
 * corresponds to. If the window's 375px wide (fairly typical for our standard
 * mobile visitors) it'll return { width: 375, breakpoint: Breakpoints.xs },
 * since 360px is the smallest breakpoint matching that size.
 *
 * You can optionally pass in an initialWidth value to customize the SSR
 * outcome. The default value is Breakpoints.xs, as this optimizes the SSR
 * versions of components for the devices most commonly used to visit the site.
 */
export const useBreakpoint = (
  initialWidth = Breakpoints.bpExtraSmall,
): { breakpoint: Breakpoints; width: number } => {
  // This handles the SSR case: we're rendering static HTML on Circle CI and
  // have no idea what the real size of the window will be. This is where we
  // use the initialWidth as a starting point.
  const initialValue = {
    breakpoint: initialWidth,
    width: initialWidth,
  }

  const [value, setValue] = React.useState(initialValue)

  // This handles window resizes and phone orientation changes. These are much
  // less important cases for us but still nice to have them work properly.
  React.useEffect(() => {
    const update = (): void =>
      setValue({
        breakpoint: getBreakpoint(window.innerWidth),
        width: window.innerWidth,
      })

    // This handles the initial client-side render. This is the earliest possible
    // moment for us to know the true size of the window.
    if (typeof window !== 'undefined') {
      update()
    }

    window.addEventListener('resize', update)
    window.addEventListener('orientationchange', update)
    return (): void => {
      window.removeEventListener('resize', update)
      window.removeEventListener('orientationchange', update)
    }
  }, [])

  return value
}
