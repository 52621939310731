import { _clamp, _sumBy } from '@naturalcycles/js-lib'
import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { MiniCart } from '@src/shop/components/mini-cart/Minicart.component'
import classNames from 'classnames'
import React, { useState } from 'react'
import styles from './CartIcon.module.scss'

enum CartIconAnimations {
  None = 'None',
  Pulse = 'Pulse',
}

const CartIcon = (): React.ReactElement => {
  const cart = useAppSelector(state => state.shopify.cart)
  const cartSize = _sumBy(Object.values(cart), c => c.quantity)
  const [animation, setAnimation] = React.useState(CartIconAnimations.None)
  const [openCart, setOpenCart] = useState(false)

  React.useEffect(() => {
    if (cartSize > 0) {
      setAnimation(CartIconAnimations.Pulse)
      const timeout = setTimeout(() => setAnimation(CartIconAnimations.None), 200)
      return () => clearTimeout(timeout)
    }
  }, [cartSize])

  const link = {
    className: styles.CartIcon,
    id: 'cart',
    href: '#cart',
  }

  const svg: React.SVGAttributes<SVGElement> = {
    className: styles.CartIcon__svg,
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 -6 46 50',
  }

  const cartBackground: React.SVGAttributes<SVGCircleElement> = {
    className: styles.CartIcon__cartBackground,
    cx: 21,
    cy: 21,
    r: 21,
  }

  const cartForeground: React.SVGAttributes<SVGPathElement> = {
    className: styles.CartIcon__cartForeground,
    d: 'M17.4 26.4c-1 0-1.8.8-1.8 1.8a1.8 1.8 0 103.6 0c0-1-.8-1.8-1.8-1.8zM12 12v1.8h1.8l3.24 6.83-1.22 2.2a1.8 1.8 0 001.58 2.67h10.8v-1.8H17.78a.22.22 0 01-.22-.22c0-.05 0-.08.02-.11l.81-1.47h6.7a1.8 1.8 0 001.58-.93l3.22-5.84a.9.9 0 00-.79-1.33H15.8l-.86-1.8H12zm14.4 14.4c-1 0-1.8.8-1.8 1.8a1.8 1.8 0 103.6 0c0-1-.8-1.8-1.8-1.8z',
  }

  const sizeGroup: React.SVGAttributes<SVGGElement> = {
    className: classNames(styles.CartIcon__size, {
      [styles.CartIcon__pulse]: animation === CartIconAnimations.Pulse,
    }),
  }

  const sizeBackground: React.SVGAttributes<SVGCircleElement> = {
    className: styles.CartIcon__sizeBackground,
    cx: 0,
    cy: 0,
    r: 10,
  }

  const sizeBackgroundWhite: React.SVGAttributes<SVGCircleElement> = {
    className: styles.CartIcon__sizeBackgroundWhite,
    cx: 0,
    cy: 0,
    r: 12,
  }

  const sizeForeground: React.SVGAttributes<SVGTextElement> = {
    className: classNames(styles.CartIcon__sizeForeground, {
      [styles.CartIcon__sizeForegroundTenOrMore]: cartSize >= 10,
    }),
    y: cartSize < 10 ? 1 : 0,
    dominantBaseline: 'middle',
    textAnchor: 'middle',
  }

  const cartNavigationSwitch = (e: React.MouseEvent): void => {
    if (location.pathname !== '/shop/cart') {
      setOpenCart(true)
    }
    e.preventDefault()
  }

  return (
    <>
      <a
        {...link}
        aria-label={`shopping cart has ${cartSize} ${cartSize === 1 ? 'item' : 'items'}`}
        aria-live="polite"
        onClick={cartNavigationSwitch} // Open MiniCart when cart icon is clicked
      >
        <svg {...svg} aria-hidden={true}>
          <circle {...cartBackground} />
          <path {...cartForeground} />
          {cartSize > 0 && (
            <g {...sizeGroup}>
              <circle {...sizeBackgroundWhite} />
              <circle {...sizeBackground} />
              <text
                style={{ fontSize: `.625rem`, transform: `translateX(-1px)` }}
                {...sizeForeground}
              >
                {_clamp(cartSize, 1, 99)}
              </text>
            </g>
          )}
        </svg>
      </a>
      <ClientOnly>
        <MiniCart openCart={openCart} setOpenCart={setOpenCart} />{' '}
      </ClientOnly>
    </>
  )
}

export default CartIcon
