import TranslationContext from '@src/context/translation.context'
import React from 'react'

export const useTranslation = (): ((key: string) => string) => {
  const translations: { [key: string]: string } = React.useContext(TranslationContext)

  return (key: string): string => {
    return translations[key]!
  }
}
